import React, { useRef } from "react";
import PropTypes from "prop-types";
import TaggedTextWithDescription from "../TaggedTextWithDescription";
import Button from "../Button";

const scrollToRef = (ref) => {
  if (typeof window !== "undefined")
    window.scrollTo(100, ref.current.offsetTop);
};

const Profiles = (props: any) => {
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  return (
    <div className="container-profiles">
      <div className="profiles-content">
        <TaggedTextWithDescription
          containerType="hero center w-container"
          subtitle="Select your profiles"
          title="Augment your team capabilities"
          titleClass="center full values-max"
          body="We allow companies to accelerate their development pace, allowing the in-house team to focus on their engineering, and avoiding the need of hands-on management."
          bodyClass="p2 center full values-max"
        />
      </div>
      <div className="wrap_profiles">
        {props.source.map((profile, index) => (
          <div className="card__profile" key={`profile-${index}-card`}>
            <div className="card_box" key={`profile-${index}-box`}>
              <div className="skills_row" key={`profile-${index}-skill`}>
                <h3 className="profile__title" key={`profile-${index}-title`}>
                  {profile.title1}
                </h3>
                <h4
                  className="profile__subtitle"
                  key={`profile-${index}-subtitle`}
                >
                  skills
                </h4>
                <span
                  className="profile_desc"
                  key={`profile-${index}-desc-skills`}
                >
                  {profile.skills}
                </span>
              </div>
              {/* <div className="price_row" key={`profile-${index}-price-row`}>
                    <h4 className="profile__subtitle" key={`profile-${index}-price`}>Price Range </h4>
                    <span className="profile_desc" key={`profile-${index}-desc-price`}> {profile.price}</span>
                  </div> */}
              <div
                className="button-col"
                key={`profile-${index}-block`}
                style={{ marginBottom: 50, marginTop: 50 }}
                onClick={executeScroll}
              >
                <Button
                  spacer={false}
                  buttonClass="button-container-details"
                  buttonHrefClass="button-details green w-button availability-button"
                  buttonLabel="check availability"
                  buttonLine="button-box green"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <span ref={myRef} />
    </div>
  );
};

export default Profiles;

Profiles.propTypes = {
  source: PropTypes.array,
  divRef: PropTypes.object,
};
