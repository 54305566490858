import React, { useEffect, useRef } from "react";
import Elevator from "elevator.js";
import TaggedTextWithDescription from "../TaggedTextWithDescription";
import ProcessStep from "../ProcessStep";

const HowWeLikeThings = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const html = document.querySelector("html");
      new Elevator({
        element: document.querySelector(".title-process-end"),
        targetElement: document.querySelector(".title-process-bg"),
        mainAudio: "/music/music.mp3",
        endAudio: "/music/ding.mp3",
        verticalPadding: 50,
        duration: 5000,
        startCallback() {
          html.style.scrollBehavior = "auto";
        },

        endCallback() {
          html.style.scrollBehavior = "smooth";
        },
      });
    }
  }, []);
  const myRef = useRef(null);
  const processSteps = [
    {
      title: "Listen and Learn",
      body: "The starting point of every collaboration. We want to hear you, know your business and understand your story. We master your pain points and discovery your future of opportunities.",
      line: [11, 15, 12, 14, 13],
    },
    {
      title: "Making Sense",
      body: "We digest the information gathered and seize the opportunity to do the homework, including analyzing needs, prioritizing requirements, composing insights and framing the opportunities into a tangible project roadmap.",
      line: [17, 16, 18],
    },
    {
      title: "Validation",
      body: "We propose a solution, a path for us to travel together that will alleviate your pains and help you accomplish your goals. We review everything in detail to make sure we are on the same page and ready to put our fingers on the keyboards.",
      line: [20, 15, 12, 19, 21],
    },
    {
      title: "First Glimpse",
      body: "We prototype that solution into tangible deliveries: designs, interfaces, ideas, thoughts, business strategy, technology. It all comes together as a preview of the real deal. And then we review everything together one more time.",
      line: [17, 24, 22, 23],
    },
    {
      title: "Build and Deliver!",
      body: "We proudly work on your solution and bring it to life, ready to provide your business the boost that was missing.",
      line: [25, 15, 12, 27, 26],
    },
  ];
  return (
    <>
      <div className="process_steps-scroll">
        <div className="process_steps-content">
          <TaggedTextWithDescription
            subtitle="HOW WE LIKE TO GET THINGS DONE"
            title="Crafting Digital Products"
            titleClass="full values-max"
            body="Clients get frustrated and teams end up making readjustments in what feels like a never-ending project. To prevent this scenario we established a leading rule - we always pursue open collaborations with partners and clients."
            bodyClass="p2 full values-max"
          />
        </div>
        <span ref={myRef} className="title-process-bg">
          Begin
        </span>
        <div className="sticky">
          <span className="dot" />
        </div>
        <div className="process__row">
          <div className="process__col-spyscroll">
            <div className="stick-column" />
          </div>
          <div className="process__col-steps">
            {processSteps.map((step, index) => (
              <ProcessStep
                key={`process-step-${index}`}
                step={index + 1}
                title={step.title}
                body={step.body}
                line={step.line}
                odd={index % 2 == 0}
              />
            ))}
          </div>
        </div>
        <span className="title-process-end">Start Again</span>
      </div>
    </>
  );
};

export default HowWeLikeThings;
