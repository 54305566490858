import React from "react";
import PropTypes from "prop-types";
import { easeInOut } from "@popmotion/easing";
import random from "lodash/random";
import posed from "react-pose";
import { genRandomTopX } from "../Animations";

export const AnimatedRandomX = posed.span({
  enter: {
    rotate: 90,
    opacity: 0.5,
    y: 0,
    transition: {
      opacity: () => ({
        type: "keyframes",
        values: [
          0.5,
          random(0.5, 1),
          random(0.5, 1),
          random(0.5, 1),
          random(0.5, 1),
          0.5,
        ],
        duration: random(10, 20, true) * 1000,
        ease: easeInOut,
      }),
      y: () => ({
        type: "keyframes",
        values: [
          0,
          genRandomTopX(),
          genRandomTopX(),
          genRandomTopX(),
          genRandomTopX(),
          genRandomTopX(),
          genRandomTopX(),
          0,
        ],
        duration: random(20, 40, true) * 1000,
        ease: easeInOut,
        loop: Infinity,
      }),
    },
  },
  exit: {
    opacity: 0.5,
    y: 0,
  },
});

const RandomBlockX = (props) => (
  <AnimatedRandomX {...props} initialPose="exit" pose="enter" />
);

const ProcessStep = (props) => {
  const { step, title, body, odd } = props;
  return (
    <div
      id={`process-step-${step}`}
      className={`process-1 ${odd ? "process-col_start" : "process-col_end"}`}
    >
      <div className="wrapper-process-1">
        <div className={`number-${step}`} />
        <div className="div-block-77">
          <h3 className="heading-3">{title}</h3>
          <p className="p2 full">{body}</p>
        </div>
      </div>
      {props.line?.map((item, index) => (
        <RandomBlockX
          className={`line-${item}`}
          key={`line-${item}-${index}`}
        />
      ))}
    </div>
  );
};

ProcessStep.propTypes = {
  step: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  line: PropTypes.array,
  odd: PropTypes.bool,
};

ProcessStep.defaultProps = {
  step: 1,
  title: "",
};

export default ProcessStep;
